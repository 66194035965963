














import Vue from "vue";
import { JobListingTHead } from "@/interfaces/global_valley/job_parser/job_listing/job_listing_thead";

export default Vue.extend({
  name: "JobListingTHead",
  data(): JobListingTHead {
    return {
      table_headers: [
        {
          title: "Job Title"
        },
        {
          title: "Date"
        },
        {
          title: "Reanalysis"
        },
        {
          title: "Download CV Book"
        }
      ]
    };
  }
});
