



























































































































































































import Vue from "vue";
import { JobListingTBody } from "@/interfaces/global_valley/job_parser/job_listing/job_listing_tbody";
import { mapActions, mapGetters } from "vuex";
import {
  GET_JOBS_LISTING,
  GET_JOBS_LISTING_LOADING,
  GET_REANALYSIS_JOB_LOADING,
  REANALYSIS_JOB,
  REANALYSIS_JOB_ID
} from "@/store/modules/job_parser/constants";
import { JobListingActionPayload } from "@/store/modules/job_parser/interfaces";
import { date_format } from "@/utils/skill_extractor";
import { job_search } from "@/store/apis/skill";

export default Vue.extend({
  name: "JobListingTBody",
  data(): JobListingTBody {
    return {
      pagination: 1
    };
  },
  async mounted() {
    await this.extract_jobs();
  },
  computed: {
    ...mapGetters("job_parser", {
      get_jobs: GET_JOBS_LISTING,
      listing_jobs_loading: GET_JOBS_LISTING_LOADING,
      reanalysis_loading: GET_REANALYSIS_JOB_LOADING,
      reanalysis_job_ids: REANALYSIS_JOB_ID
    })
  },
  methods: {
    job_search,
    ...mapActions("job_parser", {
      fetch_jobs: GET_JOBS_LISTING,
      reanalysis_job: REANALYSIS_JOB
    }),
    date_format,
    async extract_jobs(page = 1, limit = 10) {
      const obj: JobListingActionPayload = {
        page: page - 1,
        limit: limit
      };
      await this.fetch_jobs(obj);
    },
    async download_book(link: string) {
      const response = await fetch(link);
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = url;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  }
});
