
























import Vue from "vue";
import JobListingTHead from "@/components/global_valley/job_parser/job_listing/JobListingTHead.vue";
import JobListingTBody from "@/components/global_valley/job_parser/job_listing/JobListingTBody.vue";

export default Vue.extend({
  name: "JobsListing",
  components: { JobListingTBody, JobListingTHead }
});
